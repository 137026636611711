<template>
  <div
    class="fill-height"
  >
    <v-row
      no-gutters
      class="fill-height"
    >
      <v-col
        cols="6"
        class="fill-height"
      >
        <slot name="map" />
      </v-col>
      <v-col
        cols="6"
        class="fill-height"
      >
        <v-card
          tile
          class="fill-height"
        >
          <o-loader v-if="loading.crossroads" />
          <div
            v-else-if="!crossroads || !crossroads.length"
            class="fill-height"
          >
            <v-card-text class="text-center font-italic">
              {{ $t('messages.views.admin.regulation.smartway.noCrossroad') }}
            </v-card-text>
          </div>
          <div
            v-else
            class="fill-height"
          >
            <perfect-scrollbar class="absolute-scrollbar d-flex flex-column">
              <v-card-title
                class="d-flex justify-space-between flex-nowrap primary--text"
              >
                <v-btn
                  icon
                  :disabled="!isPreviousCrossroadSelectable"
                  @click="previousCrossroad"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="text-truncate">{{ selectedCrossroadName }}</span>
                <v-btn
                  icon
                  :disabled="!isNextCrossroadSelectable"
                  @click="nextCrossroad"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-title>

              <v-divider />

              <v-card-title>
                <span v-if="selectedEntryPoint">
                  {{ $t('messages.views.admin.regulation.smartway.selectedEntryPointInformation') }}
                </span>
                <span v-else-if="selectedCrossroadId">
                  {{ $t('messages.views.admin.regulation.smartway.selectedCrossroadInformation') }}
                </span>
              </v-card-title>

              <v-card-text>
                <div
                  v-if="selectedEntryPoint"
                  class="flex-grow-1"
                >
                  <div
                    class="flex-grow-1"
                  >
                    <o-select
                      v-model="selectedTrafficLightPlanNumber"
                      :items="selectedCrossroad.trafficLightPlans"
                      item-value="number"
                      :item-text="(item) => item !== null
                        ? $t('messages.components.odeven.trafficLightPlanSelect.trafficLightPlanNumber', { number: item.number })
                        : ''
                      "
                      label="labels.crossroad.trafficLightPlan"
                    />

                    <o-card-stat
                      v-if="selectedEntryPointId"
                      class="history-card elevation-0 rounded-lg"
                      outlined
                      :title="$t('messages.views.admin.regulation.smartway.trendChart')"
                      :loading="loading.trend"
                      :has-data="!!measures.length || !!trend"
                      :style="{height: '378px'}"
                      @download-chart-as-png="downloadChartAsPng"
                    >
                      <template
                        v-slot:default
                        class="fill-height fill-width"
                      >
                        <trend-chart
                          v-if="measures.length || !!trend"
                          ref="chart"
                          class="fill-height fill-width"
                          :coefficients="trend !== null ? trend.coefficients : null"
                          :counting-break-point="trend !== null ? trend.countingBreakPoint : null"
                          :occupancy-break-point="trend !== null ? trend.occupancyBreakPoint : null"
                          :counting-value="selectedEntryPointCounting"
                          :occupancy-value="selectedEntryPointOccupancy"
                          :measures="measures"
                          :height="300"
                        />
                      </template>
                    </o-card-stat>

                    <v-alert
                      v-if="selectedEntryPointLastMeasure || !!trend"
                      dense
                      border="left"
                      type="info"
                      outlined
                      :icon="false"
                      class="mt-2"
                    >
                      <v-list dense>
                        <v-list-item-group v-if="selectedEntryPointLastMeasure">
                          <v-subheader>{{ $t('messages.views.admin.regulation.smartway.now') }}</v-subheader>

                          <v-list-item
                            v-if="selectedEntryPointOccupancy !== null"
                            dense
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-percent</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ $t('messages.views.admin.regulation.smartway.occupancy') }} : {{ selectedEntryPointOccupancy }}%
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            v-if="selectedEntryPointCounting !== null"
                            dense
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-counter</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ $t('messages.views.admin.regulation.smartway.counting') }} : {{ selectedEntryPointCounting }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>

                        <v-list-item-group v-if="!!trend">
                          <v-subheader>{{ $t('messages.views.admin.regulation.smartway.selectedTrafficLightPlanInformation') }}</v-subheader>

                          <v-list-item
                            v-if="trend.countingBreakPoint"
                            dense
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-alert-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ $t('messages.views.admin.regulation.smartway.saturatedAt', {count: Math.round(trend.countingBreakPoint)}) }}
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-icon>
                              <v-icon>mdi-update</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              {{ $t('messages.views.admin.regulation.smartway.computedAt', {date: moment.utc(trend.computedAt).local().format('L LTS')}) }}
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item dense>
                            <v-list-item-icon>
                              <v-icon>mdi-calculator-variant-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              <span>{{ $t('messages.views.admin.regulation.smartway.usedFunction') }} : </span>
                              <span>
                                <span>&fnof; (&#119909;) = </span>
                                <span
                                  v-for="(coefficient, power) of trend.coefficients"
                                  :key="`coefficient-${power}`"
                                >
                                  <span v-if="power"> + </span>
                                  <span>(</span>
                                  <span>{{ coefficient }}</span>
                                  <span v-if="power"> &times; &#119909;</span>
                                  <span v-if="power > 1">
                                    <sup>{{ power }}</sup>
                                  </span>
                                  <span>)</span>
                                </span>
                              </span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-alert>
                  </div>
                </div>

                <div v-else-if="selectedCrossroadId">
                  <v-alert
                    v-if="selectedCrossroad.suggestedAt"
                    dense
                    outlined
                    border="left"
                    :type="suggestionType"
                    :icon="false"
                  >
                    <v-list dense>
                      <v-list-item-group>
                        <v-subheader>{{ $t('messages.views.admin.regulation.smartway.suggestion.subheader') }}</v-subheader>

                        <v-list-item dense>
                          <v-list-item-icon>
                            <v-icon>mdi-script-text-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ $t('messages.views.admin.regulation.smartway.suggestion.suggestedTrafficLightPlan') }} : {{ suggestedTrafficLightPlanText }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item dense>
                          <v-list-item-icon>
                            <v-icon>mdi-update</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ $t('messages.views.admin.regulation.smartway.suggestion.suggestedAt', {date: moment.utc(selectedCrossroad.suggestedAt).local().format('L LTS')}) }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="suggestedTrafficLightPlanNumber !== null"
                          dense
                        >
                          <v-list-item-icon>
                            <v-icon>mdi-check-decagram-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            {{ $t('messages.views.admin.regulation.smartway.suggestion.suggestionTrust') }} : {{ Math.round(selectedCrossroad.suggestionTrust * 100) }}%
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>

                    <v-btn
                      v-if="selectedCrossroad && selectedCrossroad.suggestionScores !== null"
                      color="primary"
                      outlined
                      class="float-right"
                      @click="showScores = true"
                    >
                      {{ $t('messages.views.admin.regulation.smartway.suggestion.showDetails') }}
                    </v-btn>
                  </v-alert>

                  <v-alert
                    v-else
                    dense
                    border="left"
                    type="warning"
                    class="text-center"
                    icon="mdi-alert"
                  >
                    {{ $t('messages.views.admin.regulation.smartway.suggestion.noSuggestion') }}
                  </v-alert>

                  <v-alert
                    v-if="advices.length"
                    dense
                    border="left"
                    type="info"
                    outlined
                    prominent
                    icon="mdi-lightbulb-on-outline"
                    class="mt-2"
                  >
                    <v-list dense>
                      <v-subheader>{{ $t('messages.views.admin.regulation.smartway.advices') }}</v-subheader>
                      <v-list-item
                        v-for="(advice, i) in advices"
                        :key="`advice-${i}`"
                        dense
                      >
                        <v-list-item-title>
                          {{ advice }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-alert>
                </div>
              </v-card-text>
            </perfect-scrollbar>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <scores-modal
      v-if="showScores"
      :scores="selectedCrossroad.suggestionScores"
      @close="showScores = false"
    />
  </div>
</template>

<script>
  import WebSocketTopic from '@api/web-socket-topic';
  import webSocketClient from '@mixin/web-socket-client';
  import { getCrossroadName } from '@utils/crossroad';
  import { downloadCanvasAsPng } from '@/utils/download';
  import { getJamFactorColor, getJamFactorType } from '@/utils/section';
  import { clone } from 'lodash';

  export default {
    components: {
      TrendChart: () => import('@/views/admin/components/regulation/smartway/TrendChart'),
      ScoresModal: () => import('@/views/admin/components/regulation/smartway/ScoresModal'),
    },

    mixins: [webSocketClient],

    apollo: {
      stations: {
        query: require('@gql/views/admin/regulation/smartway/getStations.gql'),
        client: 'stations',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading.crossroads = res.loading;
        },
        update (data) {
          this.crossroads = [];
          for (const station of data.getStations) {
            for (const crossroad of station.crossroads) {
              this.crossroads.push(clone(crossroad));
            }
          }

          if (this.crossroads.length) {
            if (this.selectedCrossroadId === null) {
              this.selectedCrossroadId = this.crossroads[0].id;
            } else {
              const selectedCrossroad = this.crossroads.find((crossroad) => crossroad.id === this.selectedCrossroadId);
              selectedCrossroad.selected = true;
            }
          }

          return data.getStations;
        },
        error: function () {
          this.$flash('errors.apollo.query.crossroads', 'error');
        },
      },
      trend: {
        query: require('@gql/views/admin/regulation/smartway/getData.gql'),
        client: 'smartway',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            entryPointId: this.selectedEntryPointId,
            trafficLightPlanNumber: this.selectedTrafficLightPlanNumber,
          };
        },
        skip: function () {
          return this.selectedEntryPointId === null || this.selectedTrafficLightPlanNumber === null;
        },
        result (res, key) {
          this.loading.trend = res.loading;
        },
        update (data) {
          this.measures = data.getMeasures;

          return data.getTrend;
        },
        error: function () {
          this.$flash(null, 'error');
        },
      },
      lastMeasures: {
        query: require('@gql/views/admin/regulation/smartway/lastMeasures.gql'),
        client: 'smartway',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            entryPointIds: this.selectedCrossroad.entryPoints.map(entryPoint => entryPoint.id),
          };
        },
        skip: function () {
          return this.selectedCrossroad === null || !this.selectedCrossroad.entryPoints.length;
        },
        result (res, key) {
          this.loading.lastMeasures = res.loading;
        },
        update (data) {
          return data.getLastMeasureByEntryPointIds;
        },
        error: function () {
          this.$flash(null, 'error');
        },
      },
    },

    data: () => ({
      crossroads: [],
      selectedCrossroadId: null,
      selectedEntryPointId: null,
      selectedTrafficLightPlanNumber: 0,
      loading: {
        crossroads: true,
        trend: false,
        lastMeasures: false,
      },
      measures: [],
      measuresInterval: null,
      showScores: false,
    }),

    computed: {
      selectedCrossroad () {
        if (!this.crossroads || this.selectedCrossroadId === null) {
          return null;
        }

        return this.crossroads.find((crossroad) => crossroad.id === this.selectedCrossroadId);
      },
      selectedCrossroadName () {
        if (!this.selectedCrossroad) {
          return null;
        }

        return getCrossroadName(this.selectedCrossroad, this.stations.find((station) => station.id === this.selectedCrossroad.station));
      },
      suggestedTrafficLightPlanNumber () {
        if (this.selectedCrossroad) {
          if (this.selectedCrossroad.suggestedTrafficLightPlan) {
            const trafficLightPlan = this.selectedCrossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.id === this.selectedCrossroad.suggestedTrafficLightPlan);

            if (trafficLightPlan) {
              return trafficLightPlan.number;
            }
          }
        }

        return null;
      },
      suggestedTrafficLightPlanText () {
        if (this.selectedCrossroad) {
          if (this.selectedCrossroad.suggestedTrafficLightPlan) {
            const trafficLightPlan = this.selectedCrossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.id === this.selectedCrossroad.suggestedTrafficLightPlan);

            if (trafficLightPlan) {
              return trafficLightPlan.number;
            }
          }
        }

        return this.$t('messages.views.admin.regulation.smartway.suggestion.noTrafficLightPlan');
      },
      suggestionType () {
        if (this.selectedCrossroad && this.selectedCrossroad.suggestionTrust !== null) {
          if (this.selectedCrossroad.suggestionTrust > 0.66) {
            return 'success';
          } else if (this.selectedCrossroad.suggestionTrust > 0.33) {
            return 'warning';
          } else {
            return 'error';
          }
        }

        return 'info';
      },
      isPreviousCrossroadSelectable () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);

        return index !== -1 && index > 0;
      },
      isNextCrossroadSelectable () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);

        return index !== -1 && index < this.crossroads.length - 1;
      },
      selectedEntryPoint () {
        if (!this.crossroads || this.selectedCrossroadId === null || this.selectedEntryPointId === null) {
          return null;
        }

        return this.selectedCrossroad.entryPoints.find((entryPoint) => entryPoint.id === this.selectedEntryPointId);
      },
      selectedEntryPointLastMeasure () {
        if (this.selectedEntryPointId && !!this.lastMeasures) {
          const lastMeasure = this.lastMeasures.find(measure => measure.entryPointId === this.selectedEntryPointId);
          if (lastMeasure === null || lastMeasure === undefined) {
            return null;
          }

          return lastMeasure;
        }

        return null;
      },
      selectedEntryPointOccupancy () {
        if (this.selectedEntryPointLastMeasure === null) {
          return null;
        }

        return Math.round(this.selectedEntryPointLastMeasure.occupancy);
      },
      selectedEntryPointCounting () {
        if (this.selectedEntryPointLastMeasure === null) {
          return null;
        }

        return Math.round(this.selectedEntryPointLastMeasure.counting);
      },
      advices () {
        const advices = [];

        if (this.selectedCrossroad) {
          // No entry point
          if (!this.selectedCrossroad.entryPoints.length) {
            advices.push(this.$t('messages.views.admin.regulation.smartway.advice.configureEntryPoints'));
          }

          let totalEntryPoints = 0;
          for (const entryPoint of this.selectedCrossroad.entryPoints) {
            if (!entryPoint.sensor) {
              totalEntryPoints++;
            }
          }
          if (totalEntryPoints) {
            advices.push(this.$tc('messages.views.admin.regulation.smartway.advice.entryPointsWithoutSensor', totalEntryPoints));
          }

          let totalDurations = 0;
          for (const trafficLightPlan of this.selectedCrossroad.trafficLightPlans) {
            if (!trafficLightPlan.duration) {
              totalDurations++;
            }
          }
          for (const trafficLight of this.selectedCrossroad.trafficLights) {
            for (const trafficLightDuration of trafficLight.trafficLightDurations) {
              if (!trafficLightDuration.duration) {
                totalDurations++;
              }
            }
          }
          if (totalDurations) {
            advices.push(this.$t('messages.views.admin.regulation.smartway.advice.incompleteDurationConfiguration'));
          }
        }

        return advices;
      },
    },

    watch: {
      crossroads: function () {
        this.refreshCrossroadFeatures();
      },
      lastMeasures: function () {
        this.refreshCrossroadFeatures();
        if (this.selectedEntryPointId) {
          this.$apollo.queries.trend.refresh();
        }
      },
      selectedEntryPoint: function (newValue, oldValue) {
        if (newValue !== undefined && newValue !== null) {
          this.loading.trend = true;
          newValue.selected = true;
          this.$store.commit('map/updateFeature', {
            type: 'entryPoint',
            feature: newValue,
            refresh: false,
          });
        }
        if (oldValue !== undefined && oldValue !== null) {
          oldValue.selected = false;
          this.$store.commit('map/updateFeature', {
            type: 'entryPoint',
            feature: oldValue,
            refresh: false,
          });
        }

        this.$store.commit('map/refreshMapboxFeatures', 'entryPoint');
      },
      selectedTrafficLightPlanNumber (newValue, oldValue) {
        this.loading.trend = true;
      },
      selectedCrossroad (newValue, oldValue) {
        this.refreshCrossroadFeatures();

        let location = null;
        const points = this.getPointsFromCrossroad(newValue);

        if (points.length) {
          location = points;
        } else if (newValue.location) {
          location = newValue.location;
        }

        if (location) {
          this.$store.commit('map/flyTo', {
            location: location,
          });
        }
      },
    },

    mounted () {
      this.$store.commit('map/reset');

      this.webSocketSubscribe(WebSocketTopic.CROSSROAD_SUGGESTION_CREATED, this.onCrossroadSuggestionCreated);
    },

    created () {
      this.$bus.$on('map.feature.selected', this.onFeatureSelected);
      this.$bus.$on('map.feature.unselected', this.onFeatureUnselected);

      this.measuresInterval = window.setInterval(() => {
        this.$apollo.queries.lastMeasures.refresh();
      }, 1000 * 60);
    },

    destroyed () {
      this.$bus.$off('map.feature.selected', this.onFeatureSelected);
      this.$bus.$off('map.feature.unselected', this.onFeatureUnselected);

      window.clearInterval(this.measuresInterval);
    },

    methods: {
      refreshCrossroadFeatures () {
        const features = {
          crossroads: this.crossroads.filter((crossroad) => crossroad.location),
          trafficLights: [],
          entryPoints: [],
          exitPoints: [],
          paths: [],
        };

        if (this.selectedCrossroad !== null && this.hasLocalizedSubFeatures(this.selectedCrossroad)) {
          // Display each located crossroad except the one selected if there is "subfeatures" located
          features.crossroads = features.crossroads.filter((crossroad) => crossroad.id !== this.selectedCrossroadId);
          features.entryPoints = this.selectedCrossroad.entryPoints;
          features.exitPoints = this.selectedCrossroad.exitPoints;
          features.trafficLights = this.selectedCrossroad.trafficLights.filter(trafficLight => !!trafficLight.location);

          for (const entryPoint of this.selectedCrossroad.entryPoints) {
            let color = null;
            if (this.lastMeasures) {
              const lastMeasure = this.lastMeasures.find(lastMeasure => lastMeasure.entryPointId === entryPoint.id);
              if (lastMeasure) {
                const jamFactorType = getJamFactorType(lastMeasure.occupancy / 10);
                color = getJamFactorColor(jamFactorType);
              }
            }
            for (const path of entryPoint.paths) {
              features.paths.push({
                ...path,
                color: color,
              });
            }
          }
        }

        this.$store.commit('map/setFeatures', {
          type: 'crossroad',
          features: features.crossroads,
        });

        this.$store.commit('map/setFeatures', {
          type: 'entryPoint',
          features: features.entryPoints,
          extraProperties: {
            selectable: true,
          },
        });

        this.$store.commit('map/setFeatures', {
          type: 'exitPoint',
          features: features.exitPoints,
          extraProperties: {
            clickable: false,
            editable: false,
            draggable: false,
            selectable: false,
          },
        });

        this.$store.commit('map/setFeatures', {
          type: 'path',
          features: features.paths,
          extraProperties: {
            clickable: false,
            editable: false,
            draggable: false,
            selectable: false,
          },
        });

        this.$store.commit('map/setFeatures', {
          type: 'trafficLight',
          features: features.trafficLights,
          extraProperties: {
            clickable: false,
            editable: false,
            draggable: false,
            selectable: false,
          },
        });
      },

      onFeatureSelected ({ type, id, feature }) {
        switch (type) {
          case 'crossroad':
            this.selectCrossroad(id);
            break;
          case 'entryPoint':
            this.selectEntryPoint(id);
            break;
        }
      },

      onFeatureUnselected ({ type }) {
        switch (type) {
          case 'entryPoint':
            this.unselectEntryPoint();
            break;
        }
      },

      selectCrossroad (id) {
        this.selectedCrossroadId = id;
        this.selectedEntryPointId = null;
        this.selectedTrafficLightPlanNumber = 0;
      },

      selectEntryPoint (id) {
        this.selectedEntryPointId = id;
      },

      unselectEntryPoint () {
        this.selectedEntryPointId = null;
      },

      previousCrossroad () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);
        if (index !== -1 && index > 0) {
          this.selectCrossroad(this.crossroads[index - 1].id);
        }
      },

      nextCrossroad () {
        const index = this.crossroads.findIndex((crossroad) => crossroad.id === this.selectedCrossroadId);
        if (index !== -1 && index < this.crossroads.length - 1) {
          this.selectCrossroad(this.crossroads[index + 1].id);
        }
      },

      getPointsFromCrossroad (crossroad) {
        const points = [];

        for (const entryPoint of crossroad.entryPoints) {
          points.push(entryPoint.location);
        }
        for (const exitPoint of crossroad.exitPoints) {
          points.push(exitPoint.location);
        }
        for (const trafficLight of crossroad.trafficLights) {
          if (trafficLight.location) {
            points.push(trafficLight.location);
          }
        }

        return points;
      },

      downloadChartAsPng () {
        const canvas = this.$refs.chart.$refs.canvas;
        downloadCanvasAsPng('trend_' + this.selectedCrossroadName, canvas);
      },

      hasLocalizedSubFeatures (crossroad) {
        // Traffic Lights
        for (const trafficLight of crossroad.trafficLights) {
          if (trafficLight.location) {
            return true;
          }
        }

        // Entry Points
        if (crossroad.entryPoints.length) {
          return true;
        }

        // Exit Points
        if (crossroad.exitPoints.length) {
          return true;
        }

        // Path
        for (const entryPoint of crossroad.entryPoints) {
          if (entryPoint.paths.length) {
            return true;
          }
        }

        return false;
      },

      onCrossroadSuggestionCreated (suggestion) {
        const crossroad = this.crossroads.find(crossroad => crossroad.id === suggestion.crossroadId);
        if (crossroad) {
          let trafficLightPlanId = null;
          if (suggestion.trafficLightPlanNumber !== null && suggestion.trafficLightPlanNumber !== undefined) {
            const trafficLightPlan = crossroad.trafficLightPlans.find(trafficLightPlan => trafficLightPlan.number === suggestion.trafficLightPlanNumber);
            if (!trafficLightPlan) {
              throw new Error('Traffic light plan with number ' + suggestion.trafficLightPlanNumber + ' not found for crossroad ' + suggestion.crossroadId);
            } else {
              trafficLightPlanId = trafficLightPlan.id;
            }
          }

          crossroad.suggestedTrafficLightPlan = trafficLightPlanId;
          crossroad.suggestedAt = suggestion.suggestedAt;
          crossroad.suggestionScores = suggestion.scores;
          crossroad.suggestionTrust = suggestion.trust;
        }
      },
    },
  };
</script>

<style scoped lang="sass">
</style>
