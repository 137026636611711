import { getTopic } from '@/utils/web-socket-client';

export default {
  created () {
    this.$options._webSocketSubscriptions = {};

    let webSocketSubscriptions = this.$options.webSocketSubscriptions;

    if (webSocketSubscriptions && undefined !== this.$store.state.app.wampClient) {
      webSocketSubscriptions = webSocketSubscriptions();

      for (const webSocketSubscription of webSocketSubscriptions) {
        if (webSocketSubscription.parameters === undefined) {
          webSocketSubscription.parameters = {};
        }

        if (typeof webSocketSubscription.parameters === 'function') {
          webSocketSubscription.parameters = webSocketSubscription.parameters.call(this);
        }
        webSocketSubscription.handler = webSocketSubscription.handler.call(this);

        this.webSocketSubscribe(
          webSocketSubscription.topic,
          webSocketSubscription.handler,
          webSocketSubscription.parameters
        );
      }
    }
  },
  destroyed () {
    if (undefined !== this.$options._webSocketSubscriptions) {
      for (const id of Object.keys(this.$options._webSocketSubscriptions)) {
        this.webSocketUnsubscribe(this.$options._webSocketSubscriptions[id]);
      }
    }
  },
  methods: {
    webSocketSubscribe (topic, handler, parameters = {}) {
      const computedTopic = getTopic(topic, parameters);

      return this.$store.dispatch('wampClient/subscribe', {
        topic: computedTopic,
        handler: (args, kargs, details) => {
          handler(kargs);
        },
      }).then((subscription) => {
        this.$options._webSocketSubscriptions[subscription.id] = subscription;
        subscription._on_unsubscribe.promise.then((newSubscription) => {
          if (newSubscription !== undefined && newSubscription.id !== undefined) {
            delete this.$options._webSocketSubscriptions[subscription.id];
            this.$options._webSocketSubscriptions[newSubscription.id] = newSubscription;
          }
        });

        return subscription;
      });
    },
    webSocketUnsubscribe (subscription) {
      delete this.$options._webSocketSubscriptions[subscription.id];

      return this.$store.dispatch('wampClient/unsubscribe', subscription);
    },
  },
};
